import React, { useEffect } from 'react';
import LandingTitleSection from './LandingTitleSection';
import './Landing.css';
import LandingMInspectSection from './LandingMInspectSection';
import LandingMCarpoolSection from './LandingMCarpoolSection';

function Divider({ style, className }) {
  return (
    <div className={className} style={{ borderBottom: '1px solid #f0f0f0', ...style }} />
  );
}



export default function LandingPage() {
  useEffect(() => {
    document.title = 'PurInspect - The new way to inspect your vehicles';
    document.head.querySelector('meta[name="description"]').content = 'Simplify the way your company manages your vehicle inspections.  PurInspect enables you to record consistent, sharable and searchable inspections for your company.';
    document.head.querySelector('meta[name="keywords"]').content = 'Vehicle Inspection App, Compliance Inspection Solutions, Asset Inspection App, Vehicle Movements App';
  }, []);

  return (
    <main style={{ position: 'relative' }}>
      <LandingTitleSection />
      {/* <LandingClaimsSection /> */}
      {/* <Divider /> */}
      <LandingMInspectSection />
      <Divider />
      <LandingMCarpoolSection />
    </main>
  );
}
