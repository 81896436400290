import React from 'react';
import backgroundImage from '../../assets/backgrounds/background-1.jpg';


export default function LandingTitleSection() {
  return (
    <header className="landing-title">
      <div className="moblyxs" style={{ backgroundImage: `data:image/png;base64,${backgroundImage}` }} />
      <section className="intro">
        <div className="intro-container moblyx-centre">
          <h1 className="intro-title">PurInspect - The new way to inspect your vehicles</h1>
          <p className="intro-nonsense">Simplify the way your company manages your vehicle inspections.  PurInspect enables you to record consistent, sharable and searchable inspections for your company.</p>
          {/* <div className="intro-buttons">
            <a className="moblyx-button contact-us" href="#contact-us">Contact Us</a>
          </div> */}
        </div>
      </section>
    </header>
  );
}
