import React from 'react';
import mcarpoolLogo from '../../assets/mcarpool/carpool_normal_blue_circle_white_w.png';
import mcarpoolAppExample from '../../assets/mcarpool/examples/app.png';


export default function LandingMCarpoolSection() {
  return (
    <section className="minspect-section" id="m-carpool">
      <div className="moblyx-centre">
        <div className="minspect-header">
          <a className="minspect-logo" href="#m-carpool">
            <img src={mcarpoolLogo} alt="M Carpool" />
          </a>
          <h2 className="minspect-title">Embracing the move to Mobility as a Service</h2>
          <p className="minspect-blurb">M–Carpool has been designed by fleet and mobility experts to maximise the use of your vehicle assets to increase productivity and reduce costs.</p>
        </div>
        <div className="minspect-summary">
          <div className="container">
            {/* <div className="">
              <div className="summary-example-image-con">
                <img src="/mcarpool/examples/dashboard.png" alt="An example inspection record." />
              </div>
            </div> */}
            <div className="mcarpool-summary-example">
              <div className="summary-example-image-con">
                <img src={mcarpoolAppExample} alt="An example inspection record." />
              </div>
            </div>
            <div className="minspect-summary-points">
              <ul className="points">
                <li>
                  <h4 className="point-title">Maximise control of your vehicle pool</h4>
                  <span className="point-description">Utilise the central vehicle-pool control functionality to increase vehicle utilisation.</span>
                </li>
                <li>
                  <h4 className="point-title">Track vehicle movements</h4>
                  <span className="point-description">Monitor vehicle routes using integrated GPS and telematics.</span>
                </li>
                <li>
                  <h4 className="point-title">Analytics to improve decision making</h4>
                  <span className="point-description">
                    Review vehicle utilisation reports by customising metrics:
                    <span style={{ fontStyle: 'italic' }}> Driver, Vehicle, Pool and Location</span>
                  </span>
                </li>
                <li>
                  <h4 className="point-title">Easy to configure</h4>
                  <span className="point-description">Get started quickly with our easy to set up application. Easily adhere to Health & Safety compliance.</span>
                </li>
                <li>
                  <h4 className="point-title">Configure to meet your needs</h4>
                  <span className="point-description">Control authority of your users for peace of mind. Configure your car pool management settings.</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="minspect-summary container">
          <div className="promotional-text">
            <p>
              Maximise the use of your vehicle assets while at the same time delighting customers by
              delivering a fabulous
              customer experience.
              The M–Carpool app from Moblyx enables you to do both - and more.
            </p>
            <p>
              M Carpool supports customers aiming to fully embrace MaaS or TaaS
              by delivering a central carpool function to help optimise vehicle utilisation,
              reduce costs and increase productivity.
            </p>
          </div>
          <div className="promotional-video">
            <iframe
              title="MCarpool promotional video."
              width="100%"
              height="100%"
              src="https://www.youtube.com/embed/WdLng9K-amU"
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div>
        </div>
        <div className="minspect-buttons">
          {/* <a className="moblyx-button minspect-register" href="/footer">Contact Us</a> */}
          <a className="moblyx-button minspect-login" href="mailto:info@moblyx.com">Register your interest</a>
        </div>
      </div>
    </section>
  );
}
