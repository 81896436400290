import React from 'react';

/**
 * The main section of the application
 */
export default function Main({ children }) {
  return (
    <div style={{ flex: '1 0 auto' }}>
      { children }
    </div>
  );
}
