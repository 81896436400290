import React from 'react';
import './Header.css';
import moblyxLogo from '../../../assets/logos/reversed_moblyx_no_strapline_blue_circle.png';


function MoblyxLogo() {
  return (
    <a className="moblyx-logo" href="/">
      <h1>
        <img src={moblyxLogo} alt="Moblyx Logo" />
      </h1>
    </a>
  );
}

/**
 * The application header.
 */
export default function Header() {
  return (
    <header className="header">
      <div className="moblyx-centre">
        <ul className="nav">
          <li className="nav-section logo">
            <MoblyxLogo />
          </li>
          <li className="nav-section primary">
            <a className="nav-link" href="/minspect/login">Login to M–Inspect</a>
          </li>
          <li className="nav-section mobile">TODO</li>
        </ul>
      </div>
    </header>
  );
}
