import React, { useCallback, useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/plain.css';
import { getData } from 'country-list';

const SUCCESS_STATUS = 'SUCCESS';
const ERROR_STATUS = 'ERROR';

export default function PurCoForm() {
  const [status, setStatus] = useState('');
  const [countries] = useState(() => getData().map((data) => ({
    label: data.name,
    value: data.code,
  })));

  const submitForm = useCallback((e) => {
    e.preventDefault();
    const form = e.target;
    const data = new FormData(form);
    const xhr = new XMLHttpRequest();
    xhr.open(form.method, form.action);
    xhr.setRequestHeader('Accept', 'application/json');
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return;
      if (xhr.status === 200) {
        form.reset();
        setStatus(SUCCESS_STATUS);
      } else {
        setStatus(ERROR_STATUS);
      }
    };
    xhr.send(data);
  }, []);

  return (
    <form
      className="purco-form"
      onSubmit={submitForm}
      action="https://formspree.io/f/xknpjpwz"  //Purco - David Purinton
      //action="https://formspree.io/f/xwkydvna"  //Bynx - David Parks
      method="POST"
    >
      <label htmlFor="email">
        Email
        <input id="email" type="email" name="email" placeholder="you@example.com" required />
      </label>
      <label htmlFor="name">
        Name
        <input id="name" type="text" name="name" placeholder="John Baker" required />
      </label>
      <label htmlFor="company">
        Company Name
        <input id="company" type="text" name="company" placeholder="Your Company" required />
      </label>
      { /* eslint-disable-next-line jsx-a11y/label-has-associated-control */ }
      <label htmlFor="phone">
        Phone Number
        <PhoneInput
          id="phone"
          country="us"
          placeholder="e.g. 0800838383"
          inputProps={{
            name: 'phone',
          }}
        />
      </label>
      <label htmlFor="country">
        Country
        <select name="country" defaultValue="US" required>
          {countries.map((c) => <option key={c.value} value={c.value}>{c.label}</option>)}
        </select>
      </label>
      <label htmlFor="feedback">
        Any other details?
        <textarea id="feedback" name="feedback" placeholder="Let us know if you have questions or any feedback." />
      </label>

      {status === SUCCESS_STATUS ? <p>Thank your for your interest.</p> : <button className="moblyx-button" type="submit">Register your Interest</button>}
      {status === ERROR_STATUS && <p>Ooops! There was an error.</p>}
      <div className="purco-contact-us">
        Or contact via
        <a href="mailto:contactus@purco.com">contactus@purco.com</a>
      </div>

    </form>
  );
}
