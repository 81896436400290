import React from 'react';
import poweredByLogo from '../../assets/powered_by.png';

/**
 * The footer of the application.
 */
export default function Footer() {
  return (
    <footer className="via-footer" id="footer">
      <div className="footer-secondary">
        <div className="via-centre footer-details">
          <div className="footer-group">
            <h3 className="footer-group-title">Legal Documents</h3>
            <ul className="footer-group-list">
              <li><a target="_blank" rel="noopener noreferrer" href="/minspect/legal-documents/privacy-policy">Privacy Policy</a></li>
              <li><a target="_blank" rel="noopener noreferrer" href="/minspect/legal-documents/terms-of-use">Terms of Use</a></li>
              <li><a target="_blank" rel="noopener noreferrer" href="/minspect/legal-documents/end-user-licence-agreement">End User Licence Agreement</a></li>
            </ul>
          </div>
          <div className="footer-group">
            <h3 className="footer-group-title">Contact Address</h3>
            <ul className="footer-group-list">
              <li><span>Lowes Lane Business Park, Lowes Lane</span></li>
              <li><span>Wellesbourne, Warwickshire, CV35 9RB</span></li>
              <li><span>United Kingdom</span></li>
            </ul>
          </div>
          <div className="footer-group">
            <h3 className="footer-group-title">Find Us At</h3>
            <ul className="footer-group-list">
              <li>
                <a href="https://twitter.com/moblyx" target="_blank" rel="noopener noreferrer">
                  <i aria-label="icon: twitter" style={{ marginRight: '0.3em' }} className="anticon anticon-twitter">
                    <svg viewBox="64 64 896 896" focusable="false" className="" data-icon="twitter" width="1em" height="1em" fill="currentColor" aria-hidden="true">
                      <path d="M928 254.3c-30.6 13.2-63.9 22.7-98.2 26.4a170.1 170.1 0 0 0 75-94 336.64 336.64 0 0 1-108.2 41.2A170.1 170.1 0 0 0 672 174c-94.5 0-170.5 76.6-170.5 170.6 0 13.2 1.6 26.4 4.2 39.1-141.5-7.4-267.7-75-351.6-178.5a169.32 169.32 0 0 0-23.2 86.1c0 59.2 30.1 111.4 76 142.1a172 172 0 0 1-77.1-21.7v2.1c0 82.9 58.6 151.6 136.7 167.4a180.6 180.6 0 0 1-44.9 5.8c-11.1 0-21.6-1.1-32.2-2.6C211 652 273.9 701.1 348.8 702.7c-58.6 45.9-132 72.9-211.7 72.9-14.3 0-27.5-.5-41.2-2.1C171.5 822 261.2 850 357.8 850 671.4 850 843 590.2 843 364.7c0-7.4 0-14.8-.5-22.2 33.2-24.3 62.3-54.4 85.5-88.2z" />
                    </svg>
                  </i>
                  Twitter
                </a>
              </li>
              <li>
                <a href="https://www.youtube.com/channel/UCQq7pOIMvTmeBtGWFOZRhEQ" target="_blank" rel="noopener noreferrer">
                  <i aria-label="icon: youtube" style={{ fontSize: '1em', marginRight: '0.3em' }} className="anticon anticon-youtube">
                    <svg viewBox="64 64 896 896" focusable="false" className="" data-icon="youtube" width="1em" height="1em" fill="currentColor" aria-hidden="true">
                      <path d="M960 509.2c0-2.2 0-4.7-.1-7.6-.1-8.1-.3-17.2-.5-26.9-.8-27.9-2.2-55.7-4.4-81.9-3-36.1-7.4-66.2-13.4-88.8a139.52 139.52 0 0 0-98.3-98.5c-28.3-7.6-83.7-12.3-161.7-15.2-37.1-1.4-76.8-2.3-116.5-2.8-13.9-.2-26.8-.3-38.4-.4h-29.4c-11.6.1-24.5.2-38.4.4-39.7.5-79.4 1.4-116.5 2.8-78 3-133.5 7.7-161.7 15.2A139.35 139.35 0 0 0 82.4 304C76.3 326.6 72 356.7 69 392.8c-2.2 26.2-3.6 54-4.4 81.9-.3 9.7-.4 18.8-.5 26.9 0 2.9-.1 5.4-.1 7.6v5.6c0 2.2 0 4.7.1 7.6.1 8.1.3 17.2.5 26.9.8 27.9 2.2 55.7 4.4 81.9 3 36.1 7.4 66.2 13.4 88.8 12.8 47.9 50.4 85.7 98.3 98.5 28.2 7.6 83.7 12.3 161.7 15.2 37.1 1.4 76.8 2.3 116.5 2.8 13.9.2 26.8.3 38.4.4h29.4c11.6-.1 24.5-.2 38.4-.4 39.7-.5 79.4-1.4 116.5-2.8 78-3 133.5-7.7 161.7-15.2 47.9-12.8 85.5-50.5 98.3-98.5 6.1-22.6 10.4-52.7 13.4-88.8 2.2-26.2 3.6-54 4.4-81.9.3-9.7.4-18.8.5-26.9 0-2.9.1-5.4.1-7.6v-5.6zm-72 5.2c0 2.1 0 4.4-.1 7.1-.1 7.8-.3 16.4-.5 25.7-.7 26.6-2.1 53.2-4.2 77.9-2.7 32.2-6.5 58.6-11.2 76.3-6.2 23.1-24.4 41.4-47.4 47.5-21 5.6-73.9 10.1-145.8 12.8-36.4 1.4-75.6 2.3-114.7 2.8-13.7.2-26.4.3-37.8.3h-28.6l-37.8-.3c-39.1-.5-78.2-1.4-114.7-2.8-71.9-2.8-124.9-7.2-145.8-12.8-23-6.2-41.2-24.4-47.4-47.5-4.7-17.7-8.5-44.1-11.2-76.3-2.1-24.7-3.4-51.3-4.2-77.9-.3-9.3-.4-18-.5-25.7 0-2.7-.1-5.1-.1-7.1v-4.8c0-2.1 0-4.4.1-7.1.1-7.8.3-16.4.5-25.7.7-26.6 2.1-53.2 4.2-77.9 2.7-32.2 6.5-58.6 11.2-76.3 6.2-23.1 24.4-41.4 47.4-47.5 21-5.6 73.9-10.1 145.8-12.8 36.4-1.4 75.6-2.3 114.7-2.8 13.7-.2 26.4-.3 37.8-.3h28.6l37.8.3c39.1.5 78.2 1.4 114.7 2.8 71.9 2.8 124.9 7.2 145.8 12.8 23 6.2 41.2 24.4 47.4 47.5 4.7 17.7 8.5 44.1 11.2 76.3 2.1 24.7 3.4 51.3 4.2 77.9.3 9.3.4 18 .5 25.7 0 2.7.1 5.1.1 7.1v4.8zM423 646l232-135-232-133z" />
                    </svg>
                  </i>
                  Youtube
                </a>
              </li>
              <li>
                <a href="mailto:info@moblyx.com" style={{ display: 'flex', alignItems: 'center' }}>
                  <span role="img" aria-label="mail" style={{ fontSize: '1em', marginRight: '0.3em', lineHeight: 1 }} className="anticon anticon-mail">
                    <svg viewBox="64 64 896 896" focusable="false" className="" data-icon="mail" width="1em" height="1em" fill="currentColor" aria-hidden="true">
                      <path d="M928 160H96c-17.7 0-32 14.3-32 32v640c0 17.7 14.3 32 32 32h832c17.7 0 32-14.3 32-32V192c0-17.7-14.3-32-32-32zm-40 110.8V792H136V270.8l-27.6-21.5 39.3-50.5 42.8 33.3h643.1l42.8-33.3 39.3 50.5-27.7 21.5zM833.6 232L512 482 190.4 232l-42.8-33.3-39.3 50.5 27.6 21.5 341.6 265.6a55.99 55.99 0 0068.7 0L888 270.8l27.6-21.5-39.3-50.5-42.7 33.2z" />
                    </svg>
                  </span>
                  info@moblyx.com
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="footer-tertiary">
        <div className="via-centre footer-tertiary-contents">
          <a href="https://moblyx.com" className="via-logo via-footer-logo">
            <img className="via-img" src={poweredByLogo} alt="Moblyx Inspect" />
          </a>
          <div className="footer-copyright">{`© ${new Date().getFullYear()} Moblyx`}</div>
          <div />
        </div>
      </div>
    </footer>
  );
}
