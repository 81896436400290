import React from 'react';
import minspectLogo from '../../assets/minspect/inspect_normal_blue_circle_white_m.png';
import minspectInspectionExample from '../../assets/minspect/examples/inspection.png';

/**
 * The play store button for downloading the Moblyx Inspect app.
 */
function DownloadApp({ className, style }) {
  return (
    <a className={className} style={style} target="_blank" rel="noopener noreferrer" href="https://play.google.com/store/apps/details?id=com.moblyx.minspect&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
      <img style={{ width: '200px' }} alt="Get it on Google Play" src="https://play.google.com/intl/en_gb/badges/images/generic/en_badge_web_generic.png" />
    </a>
  );
}


export default function LandingMInspectSection() {
  return (
    <section className="minspect-section" id="m-inspect" style={{ marginTop: '0' }}>
      <div className="moblyx-centre">
        <header className="minspect-header">
          <a className="minspect-logo" href="#m-inspect">
            <img src={minspectLogo} alt="M–Inspect" />
          </a>
          <h2 className="minspect-title">The new way to Inspect your vehicles</h2>
          <p className="minspect-blurb">Simplify the way your company manages your vehicle inspections. M–Inspect enables you to record consistent, shareable and searchable inspections for your company.</p>
        </header>
        <div className="minspect-summary">
          <div className="container">
            <div className="minspect-summary-points">
              <ul className="points">
                <li>
                  <h4 className="point-title">Record on your phone</h4>
                  <span className="point-description">Share the app with your employees and inspect wherever you are.</span>
                </li>
                <li>
                  <h4 className="point-title">Interactive vehicle diagrams</h4>
                  <span className="point-description">Accurately record damages on the variety of vehicle diagram models.</span>
                </li>
                <li>
                  <h4 className="point-title">Configurable to your business needs</h4>
                  <span className="point-description">
                    Tailor your inspection to suit your business needs. This includes support for many inspection types:
                    <span style={{ fontStyle: 'italic' }}> Regulatory, Compliance, Vehicle Handover, Daily Walkaround and more.</span>
                  </span>
                </li>
                <li>
                  <h4 className="point-title">Store inspections in the cloud</h4>
                  <span className="point-description">Digitise your inspections and back them up to the cloud so you can access wherever you are in the world.</span>
                </li>
                <li>
                  <h4 className="point-title">Share with your customers</h4>
                  <span className="point-description">Send personalised emails to your customer immediately after completing their inspection.</span>
                </li>
              </ul>
            </div>
            <div className="minspect-summary-example">
              <div className="summary-example-image-con">
                <img src={minspectInspectionExample} alt="An example inspection record." />
              </div>
            </div>
          </div>
        </div>
        <div className="minspect-summary container">
          <div className="promotional-video">
            <iframe
              title="MCarpool promotional video."
              width="100%"
              height="100%"
              src="https://www.youtube.com/embed/zDfX73ACsBY"
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div>
          <div className="promotional-text">
            <p>
              Save time, increase efficiency, boost revenues, control and monitor risk,
              improve productivity and greatly enhance the customer experience with the
              M–Inspect app from Moblyx.
            </p>
            <p>
              M–Inspect digitises your asset check and vehicle inspection processing and
              in so doing, creates efficiencies plus strengthens productivity.
            </p>
          </div>
        </div>
        <div className="minspect-buttons">
          <a className="moblyx-button minspect-register" href="/minspect/sign-up">Sign Up for a free trial</a>
          <a className="moblyx-button minspect-login" href="/minspect/login">Login to your account</a>
          <div style={{ flex: '0 0 100%', display: 'flex', justifyContent: 'center' }}>
            <DownloadApp style={{ display: 'flex', justifyContent: 'center' }} />
          </div>
        </div>
      </div>
    </section>
  );
}
